<ul class="menu">

  <li class="menu__item">
    <a href="" [routerLink]="['./']" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
      <span class="material-icons icon">home</span>
    </a>
  </li>

  <li class="menu__item">
    <a href="" [routerLink]="['/', 'cats', 'search']" routerLinkActive="active">
      <span class="material-icons icon">search</span>
    </a>
  </li>

  <li class="menu__item">
    <a href="" [routerLink]="['/', 'cats', 'new']" *ngIf="(user$ | async)" routerLinkActive="active">
      <span class="material-icons icon">add_circle_outline</span>
    </a>

    <a routerLinkActive="active" *ngIf="!(user$ | async)" (click)="showAuthModal()">
      <span class="material-icons icon">add_circle_outline</span>
    </a>
  </li>

  <li class="menu__item" *ngIf="(user$ | async)">
    <a href="" [routerLink]="['menu']" routerLinkActive="active">
      <span class="material-icons-outlined icon">menu</span>
    </a>
  </li>

  <li class="menu__item" *ngIf="!(user$ | async)">
    <a href="" [routerLink]="['login']" routerLinkActive="active">
      <span class="material-icons icon">account_circle</span>
    </a>
  </li>
</ul>
