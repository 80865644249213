import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

declare let jivo_api;

declare global {
  interface Window { jivo_onLoadCallback: any; }
}

@Injectable({
  providedIn: 'root'
})
export class JivositeService {

  loaded$ = new BehaviorSubject(false);

  constructor() { }

  public open(): void {
    jivo_api.open();
  }

  public close(): void {
    jivo_api.close();
  }

  public onLoad(): void {
    this.loaded$.next(true);
  }

  public init() {
    window.jivo_onLoadCallback = () => {
      this.onLoad();
    }
  }
}
