import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StringToColorService {

  constructor() {}

  getColor(str): string {
    /* tslint:disable */
    let hash = 0;

    if (str.length === 0) null;

    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
      hash = hash & hash;
    }

    let rgb = [0, 0, 0];

    let value = (hash >> 8) & 360;

    return `hsl(${value}, 80%, 70%)`;
    /* tslint:enable */
  }

}
