import { AccountTypes } from '@core/constants/account-types.enum';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'accountType'
})
export class AccountTypePipe implements PipeTransform {

  transform(value: AccountTypes): unknown {
    if (value === AccountTypes.INDIVIDUAL) {
      return 'Частное лицо';
    }

    if (value === AccountTypes.CATTERY) {
      return 'Питомник';
    }

    if (value === AccountTypes.SHELTER) {
      return 'Приют';
    }
  }

}
