import { AccountMenuModule } from './../account-menu/account-menu.module';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BottomMenuComponent } from './bottom-menu.component';



@NgModule({
  declarations: [BottomMenuComponent],
  imports: [
    CommonModule,
    RouterModule,
    AccountMenuModule
  ],
  exports: [
    BottomMenuComponent,
  ]
})
export class BottomMenuModule { }
