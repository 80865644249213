<div class="wrapper">

  <div class="column column--image d-none d-md-block">
    <img src="../../../../assets/illustrations/registration.svg" alt="">
    <ul class="opportunities">
      <li>Размещайте объявления</li>
      <li>Добавляйте в избранное</li>
      <li>Оставляйте заметки</li>
    </ul>
  </div>

  <div class="column column--form">
    <app-login-form
      class="form"
      [ngClass]="{'hidden': display !== 'LOGIN'}"
      (loginSuccess)="onLoginSuccess($event)"
      (showRegistration)="display = 'REGISTRATION'"
      (showRecovery)="display = 'RECOVERY'"
    ></app-login-form>

    <app-registration-form
      class="form"
      [ngClass]="{'hidden': display !== 'REGISTRATION'}"
      (registrationSuccess)="onRegistrationSuccess($event)"
      (showLogin)="display = 'LOGIN'"
    ></app-registration-form>

    <app-recovery-form
      class="form"
      [ngClass]="{'hidden': display !== 'RECOVERY'}"
      (recoverySuccess)="onRecoverySuccess($event)"
      (showLogin)="display = 'LOGIN'"
    ></app-recovery-form>

    <div class="registration-success-message" *ngIf="display === 'REGISTRATION_SUCCESS'">
      <span class="title">Почти готово!</span>
      <span class="message">Мы выслали вам письмо со ссылкой для активации аккаунта.</span>
      <a href="{{emailService?.url}}" class="mail-link" *ngIf="emailService">Проверить почту</a>
    </div>

    <div class="recovery-success-message" *ngIf="display === 'RECOVERY_SUCCESS'">
      <span class="title">Готово!</span>
      <span class="message">Мы выслали вам письмо со ссылкой для восстановления пароля.</span>
      <a href="{{emailService?.url}}" class="mail-link" *ngIf="emailService">Проверить почту</a>
    </div>
  </div>

</div>
