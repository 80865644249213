import { Observable } from 'rxjs';
import { SearchService } from '@core/services/search.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-search-loading-display',
  templateUrl: './search-loading-display.component.html',
  styleUrls: ['./search-loading-display.component.scss']
})
export class SearchLoadingDisplayComponent implements OnInit {

  loading$;
  results$: Observable<[]>;

  constructor(
    private searchService: SearchService
  ) { }

  ngOnInit(): void {
    this.loading$ = this.searchService.loading$;
    this.results$ = this.searchService.results$;
  }

}
