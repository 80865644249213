import { URLS } from './../constants/urls.const';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class ApiService {
  constructor(
    private http: HttpClient
  ) { }

  private API_URL = URLS.api;

  get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http.get(`${this.API_URL}${path}`, { params });
  }

  put(path: string, body: object = {}): Observable<any> {
    return this.http.put(`${this.API_URL}${path}`, body);
  }

  post(path: string, body: object = {}): Observable<any> {
    return this.http.post(`${this.API_URL}${path}`, body);
  }

  delete(path, body: object = {}): Observable<any> {
    return this.http.delete(`${this.API_URL}${path}`);
  }
}
