import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { trigger, style, animate, transition } from '@angular/animations';
import { DeviceService } from '@core/services/device.service';
import { takeUntil } from 'rxjs/operators';
import { Unsubscribe } from '@core/helpers/unsubscribe';
import { User } from '@core/interfaces/user.interface';
import { Observable } from 'rxjs';
import { UserService } from '@core/services/user.service';
import { AuthDialogComponent } from '../../dialogs/auth-dialog/auth-dialog.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    trigger(
      'enterAnimation', [
        transition(':enter', [
          style({ transform: 'translateY(8px)', opacity: 0 }),
          animate('100ms', style({ transform: 'translateY(0)', opacity: 1 }))
        ]),
        transition(':leave', [
          style({ transform: 'translateY(0)', opacity: 1 }),
          animate('100ms', style({ transform: 'translateY(8px)', opacity: 0 }))
        ])
      ]
    )
  ],
})
export class HeaderComponent extends Unsubscribe implements OnInit {

  constructor(
    private dialog: MatDialog,
    private userService: UserService,
    private deviceService: DeviceService,
    private router: Router
  ) {
    super();
  }

  isAuth$: Observable<boolean>;
  user$: Observable<User>;
  isMobile$: Observable<boolean>;

  showUserMenu = false;

  ngOnInit(): void {
    this.isAuth$ = this.userService.isAuth$;
    this.user$ = this.userService.user$;
    this.isMobile$ = this.deviceService.device.isMobile$;
  }

  openAuthDialog(form): void {
    this.dialog.open(AuthDialogComponent, {
      width: '700px',
      autoFocus: false,
      data: { form }
    });
  }

  logout(): void {
    this.userService.logout().pipe(takeUntil(this.destroy$)).subscribe(res => {
      this.router.navigate(['/']);
    });
  }
}
