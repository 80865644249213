import { Observable, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { ENDPOINTS } from '@core/constants/endpoints.const';
import { ApiService } from '@core/services/api.service';
import { HttpResponseBase } from '@angular/common/http';
import { tap, switchMap, first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  user$ = new BehaviorSubject(null);
  isAuth$ = new BehaviorSubject(null);

  constructor(private api: ApiService) { }

  login(login: string, password: string): Observable<HttpResponseBase> {
    return this.api.post(ENDPOINTS.LOGIN, { login, password }).pipe(
      switchMap(() => {
        return this.getCurrent();
      }
    ));
  }

  logout() {
    return this.api.post(ENDPOINTS.LOGOUT, {}).pipe(
      tap(() => {
        this.isAuth$.next(false);
        this.user$.next(null);
      }
    ));
  }

  registration(email: string, password: string): Observable<HttpResponseBase> {
    return this.api.post(ENDPOINTS.REGISTRATION, { email, password });
  }

  getCurrent() {
    return this.api.get(ENDPOINTS.CURRENT_USER).pipe(tap(val => {

      this.user$.next(val);

      if (val) {
        this.isAuth$.next(true);

      } else {
        this.isAuth$.next(false);
      }
    }));
  }

  activate(key): Observable<HttpResponseBase> {
    return this.api.post(ENDPOINTS.ACTIVATION, { key }).pipe(
      switchMap((response) => {
        return this.getCurrent();
      })
    );
  }

  recoveryRequest(login): Observable<HttpResponseBase> {
    return this.api.post(ENDPOINTS.RECOVERY_REQUEST, { login });
  }

  checkRecoveryKey(key): Observable<HttpResponseBase> {
    return this.api.get(`${ENDPOINTS.CHECK_RECOVERY_KEY}/${key}`);
  }

  resetPassword(newPassword, recoveryKey): Observable<HttpResponseBase> {
    return this.api.post(`${ENDPOINTS.RESET_PASSWORD}`, { newPassword, recoveryKey });
  }

  getById(id: string): Observable<HttpResponseBase> {
    return this.api.get(`${ENDPOINTS.GET_USER_BY_ID.replace('${id}', id)}`);
  }

}
