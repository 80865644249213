import { MAIL_SERVICES } from '@core/constants/mail-services.const';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-login-dialog',
  templateUrl: './auth-dialog.component.html',
  styleUrls: ['./auth-dialog.component.scss']
})
export class AuthDialogComponent implements OnInit {

  display = 'LOGIN';
  emailService = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<AuthDialogComponent>,
  ) { }

  ngOnInit(): void {
    this.display = this.data.form || 'LOGIN';
  }

  onLoginSuccess(event): void {
    this.dialogRef.close();
  }

  onRegistrationSuccess(email): void {
    const domain = email.split('@')[1];
    this.emailService = MAIL_SERVICES.find(service => service.domain === domain);
    this.display = 'REGISTRATION_SUCCESS';
  }

  onRecoverySuccess(email): void {
    const domain = email.split('@')[1];
    this.emailService = MAIL_SERVICES.find(service => service.domain === domain);
    this.display = 'RECOVERY_SUCCESS';
  }

}
