import { JivositeService } from '@shared/components/jivosite/jivosite.service';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';

import { Observable, pipe } from 'rxjs';
import { LayoutService } from '@core/services/layout.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  showHeader$: Observable<boolean>;
  showFooter$: Observable<boolean>;
  background$: Observable<boolean>;
  footerUnderDisplay$: Observable<boolean>;

  constructor(
    private layoutService: LayoutService,
    private cdRef: ChangeDetectorRef,
    private jivositeService: JivositeService
  ) {

  }

  ngOnInit(): void {
    this.showHeader$ = this.layoutService.showHeader$;
    this.showFooter$ = this.layoutService.showFooter$;
    this.background$ = this.layoutService.background$;
    this.footerUnderDisplay$ = this.layoutService.footerUnderDisplay$;
    this.cdRef.detectChanges();
    this.jivositeService.init();


  }

}
