import { NotFoundModule } from './modules/not-found/not-found.module';
import { RouterModule } from '@angular/router';
import { PipesModule } from '@shared/pipes/pipes.module';
import { CoreModule } from './core/core.module';

// import { TransferHttpCacheModule } from '@nguniversal/common';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';

import { MainModule } from './modules/main/main.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injectable, ErrorHandler } from '@angular/core';

import { NgxMaskModule } from 'ngx-mask';

import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatMenuModule } from '@angular/material/menu';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { LazyLoadImageModule, ScrollHooks } from 'ng-lazyload-image';

import { SortablejsModule } from 'ngx-sortablejs';

import 'hammerjs';

import * as Sentry from '@sentry/browser';

Sentry.init({
  dsn: 'https://374b30b65fa14caaa8721d28058419fb@o316467.ingest.sentry.io/5524386',
});

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() { }
  handleError(error) {
    // const eventId = Sentry.captureException(error.originalError || error);
    // Sentry.showReportDialog({ eventId });
    const exception = error.error || error.message || error.originalError || error;
    Sentry.captureException(exception);
  }
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    MainModule,
    BrowserModule.withServerTransition({ appId: 'kotopoisk' }),
    AppRoutingModule,
    HttpClientModule,
    // ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    BrowserAnimationsModule,
    MatDialogModule,
    LazyLoadImageModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatRadioModule,
    MatMenuModule,
    FormsModule,
    CoreModule,
    RouterModule,
    NotFoundModule,
    NgxMaskModule.forRoot(),
    ReactiveFormsModule,
    SortablejsModule.forRoot({}),
    PipesModule,
    NgxPageScrollCoreModule,
    // TransferHttpCacheModule,
  ],
  providers: environment.production ? [
    { provide: ErrorHandler, useClass: SentryErrorHandler }
  ] : [],
  bootstrap: [AppComponent]
})
export class AppModule { }
