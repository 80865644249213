<div class="back">

</div>
<div class="container-fluid menu-container">
  <div class="container">
    <div class="row">
      <div class="col-12 menu">
        <a href="" class="logo" [routerLink]="['/']">
          <div class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192.05 216.21"><defs><style>.cls-1{fill:#ee7469;}</style></defs><g><g id="Слой_1-2" data-name="Слой 1"><path class="cls-1" d="M192.05,193.26l-40.59-40.58a78.91,78.91,0,1,0-23,22.95l40.58,40.58ZM85.41,165.92a56.34,56.34,0,1,1,56.34-56.34A56.34,56.34,0,0,1,85.41,165.92Z"/><path class="cls-1" d="M163.55,74c1.68-8.91,4.06-22.17,5.58-33.19,2.35-17,3.18-27,.46-34.33-1.53-4.17-6.47-9.09-13.87-4.8-5,2.92-9,6.36-16.62,13.14-5.7,5.06-13.62,13.49-17.14,17.05A79.11,79.11,0,0,1,163.55,74Z"/><path class="cls-1" d="M7.75,74C6.07,65.07,3.68,51.81,2.16,40.79c-2.35-17-3.18-27-.46-34.33,1.54-4.17,6.47-9.09,13.87-4.8C20.62,4.58,24.56,8,32.2,14.8c5.7,5.06,13.62,13.49,17.14,17.05A79.11,79.11,0,0,0,7.75,74Z"/></g></g></svg>
          </div>
          <span>Котопоиск</span>
        </a>

        <div class="buttons d-none d-md-flex">
          <a href="" [routerLink]="['/', 'cats', 'search']">
            <button class="button button--outlined d-none d-lg-flex">Искать объявления</button>
            <button class="button button--outlined d-lg-none">Искать</button>
          </a>

          <a href="" [routerLink]="['/', 'cats', 'new']">
            <button class="button button--outlined-secondary d-none d-lg-flex" *ngIf="(isAuth$ | async) === true">Создать объявление</button>
            <button class="button button--outlined-secondary d-lg-none" *ngIf="(isAuth$ | async) === true">Создать</button>
          </a>
        </div>

        <div class="auth-buttons" *ngIf="(isAuth$ | async) === false">
          <span class="material-icons login-icon d-md-none" [routerLink]="['/', 'login']">login</span>
          <button class="button button--link d-none d-md-flex" (click)="openAuthDialog('LOGIN')">Вход</button>
          <button class="button button d-none d-md-flex" (click)="openAuthDialog('REGISTRATION')">Регистрация</button>
        </div>

        <div
          class="auth-buttons"
          *ngIf="isAuth$ | async"
          appClickOutside
          (clickOutside)="showUserMenu = false"
        >
          <div class="user-menu-toggle-buttons" (click)="showUserMenu = !showUserMenu">
            <div class="user-button">
              <span class="d-none d-md-flex user-button__text">{{(user$ | async).email}}</span>
              <span class="d-none d-md-flex material-icons user-button__icon" [ngClass]="{'hidden': showUserMenu}">keyboard_arrow_down</span>
              <span class="d-none d-md-flex material-icons user-button__icon" [ngClass]="{'hidden': !showUserMenu}">keyboard_arrow_up</span>
            </div>
            <span class="user-button__icon d-md-none material-icons">
              person
            </span>
          </div>

          <div
            class="user-menu"
            *ngIf="showUserMenu"
            [@enterAnimation]
          >
            <ul (click)="showUserMenu = false">
              <li class="user-menu--account" [routerLink]="['/profile', (user$ | async)._id]">
                <app-avatar
                  [name]="(user$ | async).email"
                  [size]="40"
                  [id]="(user$ | async)._id"
                ></app-avatar>
                <div class="user-menu__user-info">
                  <span>{{(user$ | async).name}}</span>
                  <span>{{(user$ | async).accountType | accountType}}</span>
                </div>
              </li>
              <li [routerLink]="['/', 'account', 'edit-ads']"><i class="material-icons">account_circle</i>Личный кабинет</li>
              <li [routerLink]="['/', 'account', 'settings']"><i class="material-icons">settings</i>Настройки</li>
              <li [routerLink]="['/', 'account', 'favorits']"><i class="material-icons">favorite_border</i>Избранное</li>
              <li [routerLink]="['/', 'account', 'history']"><i class="material-icons">history</i>История</li>
              <li [routerLink]="['/', 'cats', 'new']"><i class="material-icons">add_circle_outline</i>Создать объявление</li>
              <li (click)="logout()"> <i class="material-icons">exit_to_app</i> Выход</li>
            </ul>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
