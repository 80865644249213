import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { BREAKPOINTS } from '../constants/breakpoints.const';
import { Device } from '../interfaces/device.interface';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {
  public device: Device = {
    isMobile$: new BehaviorSubject<boolean>(false).asObservable(),
    isTablet$: new BehaviorSubject<boolean>(false).asObservable(),
    isDesktop$: new BehaviorSubject<boolean>(false).asObservable(),
    isLargeDesktop$: new BehaviorSubject<boolean>(false).asObservable()
  };

  constructor(
    private readonly breakpointObserver: BreakpointObserver
  ) {
    this.initDeviceBreakpoints();
  }

  private initDeviceBreakpoints(): void {
    this.device.isMobile$ = this.breakpointObserver.observe(BREAKPOINTS.Mobile)
      .pipe(
        map(state => state.matches)
      );

    this.device.isTablet$ = this.breakpointObserver.observe(BREAKPOINTS.Tablet)
      .pipe(
        map(state => state.matches)
      );

    this.device.isDesktop$ = this.breakpointObserver.observe(BREAKPOINTS.Desktop)
      .pipe(
        map(state => state.matches)
      );

    this.device.isLargeDesktop$ = this.breakpointObserver.observe(BREAKPOINTS.LargeDesktop)
      .pipe(
        map(state => state.matches)
      );
  }
}
