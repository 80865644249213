import { Unsubscribe } from '@core/helpers/unsubscribe';
import { UserService } from '@core/services/user.service';
import { Component, OnInit, Output, EventEmitter, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators, Form, UntypedFormGroup } from '@angular/forms';
import { CustomFormValidators } from '@core/helpers/custom-form-validators';
import { takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent extends Unsubscribe implements OnInit, AfterViewInit {

  @Output() loginSuccess = new EventEmitter();
  @Output() showRegistration = new EventEmitter();
  @Output() showRecovery = new EventEmitter();

  @ViewChild('input') inputEl: ElementRef;


  form: UntypedFormGroup;
  authenticationFailed = false;
  userIsInactive = false;
  showPassword = false;

  constructor(
    private fb: UntypedFormBuilder,
    private user: UserService
  ) {
    super();
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      email: new UntypedFormControl('', {
        validators: [
          Validators.required,
          CustomFormValidators.emailValidator
        ]
      }),
      password: new UntypedFormControl('', {
        validators: [
          Validators.required
        ]
      }),
    });

    this.form.controls.email.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(email => {
      if (email[0] === ' ' || email[email.length - 1] === ' ') {
        this.form.controls.email.setValue(email.trim());
      }
    });
  }

  ngAfterViewInit(): void {
    this.inputEl.nativeElement.focus();
  }

  apply(): void {
    this.authenticationFailed = false;

    if (this.form.invalid) {
      return;
    }

    this.user.login(this.form.value.email, this.form.value.password).pipe(takeUntil(this.destroy$)).subscribe(res => {
      this.loginSuccess.emit();
    }, err => {
      if (err) {
        if (err.error?.message === 'User is inactive') {
          this.userIsInactive = true;
        } else {
          this.authenticationFailed = true;
        }
      }
    });
  }

  registration(): void {
    this.showRegistration.emit();
  }

  recovery(): void {
    this.showRecovery.emit();
  }

}
