<div class="content">
  <span class="title">Вход</span>

  <form [formGroup]="form" (ngSubmit)="apply()">
    <div class="input-wrapper" [class.error]="(form.controls.email.invalid && form.controls.email.touched) || authenticationFailed || userIsInactive">
      <span class="label">E-mail:</span>
      <input class="input" formControlName="email" #input>
      <span class="validation-error" *ngIf="form.controls.email.hasError('required') && form.controls.email.touched">Введите e-mail</span>
      <span class="validation-error" *ngIf="form.controls.email.hasError('invalidEmail') && form.controls.email.touched">Введите корректный e-mail</span>
    </div>

    <div class="input-wrapper" [class.error]="(form.controls.password.hasError('required') && form.controls.password.touched) || authenticationFailed || userIsInactive">
      <span class="label">Пароль:</span>
      <input class="input" type="{{showPassword ? '' : 'password'}}" formControlName="password">
      <div class="password-visibility-button">
        <i class="material-icons pointer show-pass no-select" (click)="showPassword = false" *ngIf="showPassword">visibility</i>
        <i class="material-icons pointer hide-pass no-select" (click)="showPassword = true" *ngIf="!showPassword">visibility_off</i>
      </div>
      <span class="validation-error" *ngIf="form.controls.password.invalid && form.controls.password.touched">Введите пароль</span>
      <span class="validation-error" *ngIf="authenticationFailed && !form.controls.password.invalid">Неправильный логин или пароль</span>
      <span class="validation-error" *ngIf="userIsInactive">Пользователь не активирован. Проверьте почту.</span>
    </div>

    <div class="buttons">
      <button class="button apply-button">Войти</button>
      <span class="forget-password" (click)="recovery()">Забыли пароль?</span>
    </div>

  </form>
</div>

<div class="footer">
  <span class="registration-text">Еще нет аккаунта?</span>
  <span class="registration" (click)="registration()">Зарегистрируйтесь</span>
</div>
