export const BREAKPOINTS = {
  XSmall: 320,
  Small: 768,
  Medium: 1024,
  Large: 1200,

  Mobile: '(max-width: 767px)',
  Tablet: '(min-width: 768px) and (max-width: 991px)',
  Desktop: '(min-width: 992px) and (max-width: 1199px)',
  LargeDesktop: '(min-width: 1200px)'
};
