import { Observable } from 'rxjs';
import { UserService } from '@core/services/user.service';
import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { map } from 'rxjs/operators';

@Injectable()
export class NoAuthGuard implements CanActivate {

  constructor(
    private userService: UserService,
    private router: Router,
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    return this.userService.getCurrent().pipe(map((data) => {
      if (!data) {
        return true;

      } else {
        this.router.navigate(['/']);
        return false;
      }
    }));
  }
}
