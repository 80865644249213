<div class="content">
  <span class="title">Регистрация</span>

  <form [formGroup]="form" (ngSubmit)="apply()">
    <div class="input-wrapper" [class.error]="(form.controls.email.invalid && form.controls.email.touched) || loginIsUsed">
      <span class="label">E-mail:</span>
      <input class="input" formControlName="email" #input>
      <span class="validation-error" *ngIf="form.controls.email.hasError('required') && form.controls.email.touched">Введите e-mail</span>
      <span class="validation-error" *ngIf="form.controls.email.hasError('invalidEmail') && form.controls.email.touched">Введите корректный e-mail</span>
      <span class="validation-error" *ngIf="loginIsUsed">Этот адрес уже зарегистрирован</span>
    </div>

    <div class="input-wrapper" [class.error]="(form.controls.password.hasError('required') && form.controls.password.touched) || authenticationFailed">
      <span class="label">Пароль:</span>
      <input class="input" type="{{showPassword ? '' : 'password'}}" formControlName="password">

      <div class="password-visibility-button">
        <i class="material-icons pointer show-pass no-select" (click)="showPassword = false" *ngIf="showPassword">visibility</i>
        <i class="material-icons pointer hide-pass no-select" (click)="showPassword = true" *ngIf="!showPassword">visibility_off</i>
      </div>

      <div *ngIf="form.controls.password.touched">
        <span class="validation-error" *ngIf="(form.controls.password.errors | first) === 'required'">Введите пароль</span>
        <span class="validation-error" *ngIf="(form.controls.password.errors | first) === 'minlength'">Не менее 8 символов</span>
        <span class="validation-error" *ngIf="(form.controls.password.errors | first) === 'maxlength'">Не более 50 символов</span>
        <span class="validation-error" *ngIf="(form.controls.password.errors | first) === 'invalidPassword'">Пароль не должен содержать букв русского алфавита</span>
      </div>
    </div>

    <div class="buttons">
      <button class="button apply-button">Зарегистрироваться</button>
    </div>
  </form>
</div>

<div class="footer">
  <span class="login-text">Есть аккаунт?</span>
  <span class="login" (click)="login()">Войдите</span>
</div>
