import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotFoundComponent } from './modules/not-found/not-found.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule)
  },

  {
    path: 'menu',
    loadChildren: () => import('./modules/menu/menu.module').then(m => m.MenuModule)
  },

  {
    path: 'login',
    loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule)
  },

  {
    path: 'registration',
    loadChildren: () => import('./modules/registration/registration.module').then(m => m.RegistrationModule)
  },

  {
    path: 'activation',
    loadChildren: () => import('./modules/activation/activation.module').then(m => m.ActivationModule)
  },

  {
    path: 'recovery',
    loadChildren: () => import('./modules/recovery/recovery.module').then(m => m.RecoveryModule)
  },

  {
    path: 'reset-password',
    loadChildren: () => import('./modules/reset-password/reset-password.module').then(m => m.ResetPasswordModule)
  },

  {
    path: 'profile-edit',
    loadChildren: () => import('./modules/profile-edit/profile-edit.module').then(m => m.ProfileEditModule)
  },

  {
    path: 'profile/:id',
    loadChildren: () => import('./modules/profile/profile.module').then(m => m.ProfileModule)
  },

  {
    path: 'account',
    loadChildren: () => import('./modules/account/account.module').then(m => m.AccountModule)
  },

  {
    path: 'favorits',
    loadChildren: () => import('./modules/favorits/favorits.module').then(m => m.FavoritsModule)
  },

  {
    path: 'cats/:id/edit',
    loadChildren: () => import('./modules/edit-advertisement/edit-advertisement.module').then(m => m.EditAdvertisementModule)
  },

  {
    path: 'cats/new',
    loadChildren: () => import('./modules/edit-advertisement/edit-advertisement.module').then(m => m.EditAdvertisementModule)
  },

  {
    path: 'cats/search',
    loadChildren: () => import('./modules/search/search.module').then(m => m.SearchModule)
  },

  {
    path: 'cats/:id',
    loadChildren: () => import('./modules/advertisement/advertisement.module').then(m => m.AdvertisementModule)
  },

  {
    path: 'advertisements/:id',
    redirectTo: 'cats/:id'
  },

  {
    path: 'advertisements/search',
    redirectTo: 'cats/search'
  },

  {
    path: 'articles',
    loadChildren: () => import('./modules/articles/articles.module').then(m => m.ArticlesModule)
  },

  {
    path: 'articles/:categorySlug',
    loadChildren: () => import('./modules/articles/articles.module').then(m => m.ArticlesModule)
  },

  {
    path: 'articles/:categorySlug/:articleSlug',
    loadChildren: () => import('./modules/article/article.module').then(m => m.ArticleModule)
  },

  {
    path: 'feedback',
    loadChildren: () => import('./modules/feedback/feedback.module').then(m => m.FeedbackModule)
  },

  {
    path: 'faq',
    loadChildren: () => import('./modules/faq/faq.module').then(m => m.FaqModule)
  },

  {
    path: '404',
    component: NotFoundComponent
  },

  {
    path: '**',
    redirectTo: '404'
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking',
    relativeLinkResolution: 'legacy'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
