import { takeUntil } from 'rxjs/operators';
import { Unsubscribe } from '@core/helpers/unsubscribe';
import { CustomFormValidators } from '@core/helpers/custom-form-validators';
import { UserService } from '@core/services/user.service';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { interval, Subject } from 'rxjs';

@Component({
  selector: 'app-recovery-form',
  templateUrl: './recovery-form.component.html',
  styleUrls: ['./recovery-form.component.scss']
})
export class RecoveryFormComponent extends Unsubscribe implements OnInit, OnDestroy {

  @Output() recoverySuccess = new EventEmitter();
  @Output() showLogin = new EventEmitter();

  form: UntypedFormGroup;
  emailIsNotFound = false;
  tooOften = false;

  stop$ = new Subject<boolean>();


  timeout: number = null;

  constructor(
    private fb: UntypedFormBuilder,
    private user: UserService
  ) {
    super();
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      email: new UntypedFormControl('', {
        validators: [
          Validators.required,
          CustomFormValidators.emailValidator
        ]
      })
    });
  }

  ngOnDestroy(): void {
    this.stopTimer();
  }

  apply(): void {
    this.emailIsNotFound = false;
    this.tooOften = false;

    const email = this.form.value.email;

    this.emailIsNotFound = false;
    this.tooOften = false;

    this.user.recoveryRequest(email).pipe(takeUntil(this.destroy$)).subscribe(res => {
      this.recoverySuccess.emit(email);

    }, err => {
      this.emailIsNotFound = err?.status === 404;
      this.tooOften = err?.status === 400;
      this.timeout = Math.round(err?.error?.timeout / 1000);
      this.startTimer();
    });
  }

  startTimer(): void {
    interval(1000).pipe(takeUntil(this.stop$)).subscribe(() => {

      if (this.timeout > 1) {
        this.timeout -= 1;
      } else {
        this.stopTimer();
        this.tooOften = false;
      }
    });
  }

  stopTimer(): void {
    this.stop$.next(true);
    this.stop$.complete();
  }

  login(): void {
    this.showLogin.emit();
  }

}
