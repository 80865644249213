import { takeUntil } from 'rxjs/operators';
import { CustomFormValidators } from '@core/helpers/custom-form-validators';
import { UserService } from '@core/services/user.service';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit, Input } from '@angular/core';
import { Unsubscribe } from '@core/helpers/unsubscribe';

@Component({
  selector: 'app-registration-form',
  templateUrl: './registration-form.component.html',
  styleUrls: ['./registration-form.component.scss']
})
export class RegistrationFormComponent extends Unsubscribe implements OnInit, AfterViewInit {

  @Input() focus = true;

  @Output() registrationSuccess = new EventEmitter();
  @Output() showLogin = new EventEmitter();

  @ViewChild('input') inputEl: ElementRef;

  loginIsUsed = false;

  form: UntypedFormGroup;
  authenticationFailed = false;
  showPassword = false;


  constructor(
    private fb: UntypedFormBuilder,
    private user: UserService
  ) {
    super();
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      email: new UntypedFormControl('', {
        validators: [
          Validators.required,
          CustomFormValidators.emailValidator
        ]
      }),

      password: new UntypedFormControl('', {
        validators: [
          CustomFormValidators.passwordValidator,
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(50),
        ]
      }),
    });
  }

  ngAfterViewInit(): void {
    if (this.focus) {
      this.inputEl.nativeElement.focus();
    }
  }

  apply(): void {
    if (this.form.invalid) {
      return;
    }

    this.user.registration(this.form.value.email, this.form.value.password).pipe(takeUntil(this.destroy$)).subscribe(res => {
      this.loginIsUsed = false;
      this.registrationSuccess.emit(this.form.value.email);
    }, err => {
      const errors = err?.error?.errors;
      if (errors && errors.find(error => error.msg === 'E-mail already in use')) {
        this.loginIsUsed = true;
      }
    });
  }

  login(): void {
    this.showLogin.emit();
  }
}
