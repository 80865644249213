import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[appClickOutside]'
})
export class ClickOutsideDirective {

  @Input() clickOutsideExcludes: HTMLElement[] = [];

  @Output() clickOutside = new EventEmitter();

  clickedInside: boolean;
  clickedOnExclude: boolean;

  constructor(private elementRef: ElementRef) { }

  @HostListener('document:click', ['$event.target'])
  onClick(targetElement) {
    this.clickedInside = this.checkInside(targetElement);
    this.clickedOnExclude = this.checkExcludes(targetElement);

    if (!this.clickedInside && !this.clickedOnExclude) {
      this.clickOutside.emit({
        target: targetElement
      });
    }
  }

  checkInside(target: HTMLElement): boolean {
    return this.elementRef.nativeElement.contains(target);
  }

  checkExcludes(target: HTMLElement): boolean {
    for (const excludedNode of this.clickOutsideExcludes) {
      if (excludedNode.contains(target)) {
        return true;
      }
    }
    return false;
  }
}
