import { RecoveryFormModule } from '../../components/recovery-form/recovery-form.module';
import { RegistrationFormModule } from '@shared/components/registration-form/registration-form.module';
import { LoginFormModule } from '@shared/components/login-form/login-form.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthDialogComponent } from './auth-dialog.component';



@NgModule({
  declarations: [AuthDialogComponent],
  imports: [
    RecoveryFormModule,
    CommonModule,
    LoginFormModule,
    RegistrationFormModule
  ]
})
export class AuthDialogModule { }
