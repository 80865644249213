import { DOCUMENT, isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, ReplaySubject, BehaviorSubject } from 'rxjs';
import { filter, first, map, skipWhile } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RouteHistoryService {

  private history: string[] = [];
  private routes$ = new BehaviorSubject(null);

  constructor(
    private router: Router,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId
  ) {
  }

  start(): void {
    if (isPlatformServer(this.platformId)) {
      return;
    }

    this.router.events.pipe(
      filter(e => e instanceof NavigationEnd),
      map((e: NavigationEnd) => `${e.url}`),
    ).subscribe(url => this.setUrl(url));
  }

  getReferrer(): Observable<string> {
    return this.routes$.pipe(first());
  }

  setUrl(url: string): void {
    this.history.push(url);

    if (this.getLast()) {
      this.routes$.next(this.getLast());
    }
  }

  deleteLastUrl(): void {
    this.history.pop();
    this.history.pop();
    this.routes$.next(this.getLast());
  }

  private getLast(): string {
    return this.history[this.history.length - 2];
  }
}
