import { PricePipe } from './price.pipe';
import { DateToTextPipe } from './date-to-text.pipe';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FirstKeyPipe } from './first-key.pipe';
import { CasesPipe } from './cases.pipe';
import { ImageUrlPipe } from './image-url.pipe';
import { AgePipe } from './age.pipe';
import { NamePipe } from './name.pipe';
import { AccountTypePipe } from './account-type.pipe';
import { TextPipe } from './text.pipe';
import { PhonePipe } from './phone.pipe';



@NgModule({
  declarations: [
    FirstKeyPipe,
    CasesPipe,
    DateToTextPipe,
    ImageUrlPipe,
    AgePipe,
    NamePipe,
    AccountTypePipe,
    TextPipe,
    PricePipe,
    PhonePipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    FirstKeyPipe,
    CasesPipe,
    DateToTextPipe,
    ImageUrlPipe,
    AgePipe,
    NamePipe,
    AccountTypePipe,
    TextPipe,
    PricePipe,
    PhonePipe
  ]
})
export class PipesModule { }
