<div class="wrapper" *ngIf="(loading$ | async) && !(results$ | async)?.length">

  <div class="d-none d-lg-flex first-loading">
    <app-loader2></app-loader2>
    <span class="message">Ищем вам котиков. Подождите</span>
  </div>

  <div class="skeleton d-lg-none">
    <div class="container">
      <div class="row">

        <div class="d-none d-lg-flex col-4">
          <div class="skeleton__sidebar">
            <div class="skeleton__sidebar-item skeleton-glow" *ngFor="let card of [1,2,3,4,5,6,7,8,9,0]"></div>
          </div>
        </div>

        <div class="col-12">
          <div class="skeleton__top-panel">
            <div class="skeleton__filters skeleton-glow"></div>
            <div class="skeleton__num-results skeleton-glow"></div>
            <div class="skeleton__sort skeleton-glow"></div>
          </div>

          <div class="skeleton__card" *ngFor="let card of [1,2,3]">
            <div class="skeleton__card-img skeleton-glow"></div>

            <div class="skeleton__card-content">
              <div class="skeleton__card-info">
                <div class="skeleton__card-name skeleton-glow"></div>
                <div class="skeleton__card-breed skeleton-glow"></div>
                <div class="skeleton__card-city skeleton-glow"></div>
                <div class="skeleton__card-like skeleton-glow"></div>
                <div class="skeleton__card-city-bottom skeleton-glow"></div>
              </div>

              <div class="skeleton__card-contacts">
                <div class="skeleton__card-name skeleton-glow"></div>
                <div class="skeleton__card-phone skeleton-glow"></div>
                <div class="skeleton__card-author-name skeleton-glow"></div>
                <div class="skeleton__card-author-type skeleton-glow"></div>
                <div class="skeleton__card-add-to-favorite skeleton-glow"></div>
                <div class="skeleton__card-like skeleton-glow"></div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

</div>
