
<div class="app-wrapper" [ngClass]="{'footer-under-display': (footerUnderDisplay$ | async)}">
  <app-scroll-to-top-button></app-scroll-to-top-button>

  <div class="app-content" [ngClass]="{'app-content--background': (background$ | async) || false}">
    <app-header *ngIf="(showHeader$ | async) || false" class="d-none d-md-block"></app-header>
    <app-search-loading-display></app-search-loading-display>
    <router-outlet></router-outlet>
  </div>

  <div class="app-footer">
    <app-footer *ngIf="(showFooter$ | async) || false"></app-footer>
  </div>

  <app-bottom-menu class="d-md-none"></app-bottom-menu>
</div>
