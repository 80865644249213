import { HttpResponseBase } from '@angular/common/http';
import { URLS } from './../constants/urls.const';
import { ENDPOINTS } from './../constants/endpoints.const';
import { ApiService } from './api.service';
import { ImageValidatorsService } from './image-validators.service';
import { ImageFileInfo } from '../interfaces/file-info-interface';
import { map, first, switchMap } from 'rxjs/operators';
import { Observable, zip, timer, of } from 'rxjs';
import { ImageMultiplyValidation } from '@core/interfaces/image-validation.interface';
import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class ImagesService {

  constructor(
    private imageValidatorsService: ImageValidatorsService,
    private api: ApiService
  ) { }

  imagesFilter(files: File[], validationOptions: ImageMultiplyValidation, added: number): Observable<any> {
    const limit = validationOptions.maxNum - added;

    return this.getFilesInfo(files).pipe(
      first(),
      map(imagesInfo => {
        const errors = {};
        let filtered = [];

        for (let i = files.length; i--; i > 0) {
          const file = files[i];
          const fileInfo = imagesInfo[i];
          const foundErrors = this.imageValidatorsService.validate(fileInfo, validationOptions);

          if (Object.keys(foundErrors).length === 0) {
            filtered.push(file);

          } else {
            for (const err of Object.keys(foundErrors)) {
              if (foundErrors[err]) {
                errors[err] = true;
              }
            }
          }
        }

        if (filtered.length > limit) {
          filtered = filtered.splice(0, limit);
        }

        return {
          files: filtered,
          errors
        };
      })
    );
  }

  getFileInfo(file: File): Observable<ImageFileInfo> {
    const info$ = new Observable(observer => {

      if (file.type === '') {
        observer.next({
          width: null,
          height: null,
          size: file.size,
          type: null,
          name: file.name
        });
      }

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = event => {
        const img = new Image();
        img.src = event.target.result as string;

        img.onload = () => {
          observer.next({
            width: img.width,
            height: img.height,
            size: file.size,
            type: file.type.split('/')[1],
            name: file.name
          });
        };
      };
    });

    return info$;
  }

  getFilesInfo(files): Observable<ImageFileInfo[]> {
    const getFilesInfo = files.map(file => this.getFileInfo(file));
    return zip(...getFilesInfo).pipe(first());
  }


  upload(file: File): Observable<string> {
    const imageForm = new FormData();
    imageForm.append('image', file, file.name);

    return this.api.post(ENDPOINTS.UPLOAD_IMAGE, imageForm).pipe(first(), map(res => {
      return URLS.host + res.url;
    }));
  }

  remove(filename: string): Observable<HttpResponseBase> {
    return this.api.post(ENDPOINTS.REMOVE_IMAGE, { filename });
  }

}





