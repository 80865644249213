import { trigger, transition, style, animate } from '@angular/animations';
import { LayoutService } from './../../../core/services/layout.service';
import { MatDialog } from '@angular/material/dialog';
import { AuthDialogComponent } from './../../dialogs/auth-dialog/auth-dialog.component';
import { Observable, timer } from 'rxjs';
import { User } from './../../../core/interfaces/user.interface';
import { UserService } from './../../../core/services/user.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bottom-menu',
  templateUrl: './bottom-menu.component.html',
  styleUrls: ['./bottom-menu.component.scss'],
  animations: [
    trigger (
      'enterAnimation', [
        transition(':enter', [
          style({ transform: 'translateY(8px)', opacity: 0 }),
          animate('100ms', style({ transform: 'translateY(0)', opacity: 1 }))
        ]),
        transition(':leave', [
          style({ transform: 'translateY(0)', opacity: 1 }),
          animate('100ms', style({ transform: 'translateY(8px)', opacity: 0 }))
        ])
      ]
    )
  ],
})
export class BottomMenuComponent implements OnInit {

  user$: Observable<User>;
  showMenu: boolean;

  constructor(
    private userService: UserService,
    private dialog: MatDialog,
    private layoutService: LayoutService
  ) { }

  ngOnInit(): void {
    this.user$ = this.userService.user$;
  }

  showAuthModal(): void {
    this.dialog.open(AuthDialogComponent, {
      width: '700px',
      autoFocus: false,
      data: { form: 'LOGIN' }
    });
  }

  toggleMenu(): void {
    this.showMenu = !this.showMenu;

    if (this.showMenu) {
      timer(100).subscribe(() => {
        this.layoutService.disablePageScroll();
      });
    }

    if (!this.showMenu) {
      this.layoutService.enablePageScroll();
    }
  }

}
