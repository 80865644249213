export const ENDPOINTS = {
  LOGIN: 'login',
  LOGOUT: 'logout',
  CURRENT_USER: 'users/current',
  REGISTRATION: 'users',
  ACTIVATION: 'users/activate',
  RECOVERY: 'recovery-password',
  RECOVERY_REQUEST: 'send-recovery',
  CHECK_RECOVERY_KEY: 'check-recovery-key',
  RESET_PASSWORD: 'reset-password',

  SET_ACCOUNT_TYPE: 'users/current/account-type',
  SET_NAME: 'users/current/name',
  SET_BIO: 'users/current/bio',
  SET_PHONE: 'users/current/phone',
  CHANGE_PASSWORD: 'users/current/change-password',

  GET_USER_BY_ID: 'users/${id}',

  CREATE_NEW_AD: 'cats',
  EDIT_AD: 'cats/${id}',
  AD_UPLOAD_IMAGES: 'cats/${id}/upload-images',
  GET_AD: 'cats',
  GET_ADS_BY_IDS: 'cats?ids=${ids}',
  GET_BY_ID: 'cats/${id}',
  GET_BY_USERID: 'cats-by-user/${id}',
  GET_RECOMMENDED: 'recommended-cats',
  ADD_TO_FAVORITS: 'favorits/${id}',
  GET_FAVORITS: 'favorits',

  ARCHIVE_AD: 'cats/${id}/archive',
  DEARCHIVE_AD: 'cats/${id}/dearchive',
  UPDATE_AD: 'cats/${id}/update',
  DELETE_AD: 'cats/${id}',

  ARCHIVE_AD_MULTIPLE: 'cats/archive-multiple',
  UNARCHIVE_AD_MULTIPLE: 'cats/unarchive-multiple',
  UPDATE_AD_MULTIPLE: 'cats/update-multiple',

  GET_ALL_BREEDS: 'breeds',
  GET_ALL_COLORS: 'colors',
  GET_ALL_FIELDS: 'fields',

  GET_CITY_BY_KEY: 'city?key=${key}',
  GET_STATION_BY_KEY: 'station?key=${key}&cityId=${cityId}',
  GET_CITY_BY_ID: 'city-by-id?cityId=${id}',

  UPLOAD_IMAGE: 'upload-image',
  REMOVE_IMAGE: 'remove-image',

  FORM_REFS: 'form-refs',
  GET_MAIN_COMPILATION: 'get-main-compilation',

  GET_ALL_ARTICLES: 'articles',
  GET_ARTICLE_BY_SLUG: 'articles-by-slug/${slug}',
  GET_ARTICLES_BY_CATEGORY: 'articles-by-category/${category}',
  GET_CATEGORIES: 'article-categories',
  GET_CATEGORY_BY_SLUG: 'article-categories-by-slug/${slug}',

  GET_NOTE_BY_AD: 'notes/${adId}',
  NOTE_AD: 'notes',

  SET_ADVERTISEMENT_VISIT: 'statistics/cats',
  SAVE_SEARCH: 'statistics/search',

  FEEDBACK: 'feedback',

  SAVE_AD_VISIT: 'history?id=${id}',
  GET_ADS_HISTORY: 'history?page=${page}',
};
