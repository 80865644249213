import { Sorts } from '@core/constants/sorts.enum';
import { first } from 'rxjs/operators';
import { ENDPOINTS } from './../constants/endpoints.const';
import { ApiService } from './api.service';
import { Subscription, BehaviorSubject } from 'rxjs';
import { Injectable, OnInit } from '@angular/core';
import { UrlSerializer, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  public results$ = new BehaviorSubject(null);
  public pagination$ = new BehaviorSubject(null);
  public refs$ = new BehaviorSubject(null);
  public cityIsEmpty$ = new BehaviorSubject(null);
  public loading$ = new BehaviorSubject(false);

  private subscription: Subscription;

  constructor(
    private apiService: ApiService,
    private router: Router,
    private serializer: UrlSerializer,
  ) { }


  update(filters: object, sort?: Sorts, page?: number): void {
    const url = this.generateUrl([ENDPOINTS.GET_AD], filters, sort, page);

    this.loading$.next(true);
    this.subscription?.unsubscribe();

    this.subscription = this.apiService.get(url).pipe(first()).subscribe(res => {
      this.loading$.next(false);
      this.results$.next(res.data);
      this.pagination$.next(res.pagination);
      this.refs$.next(res.refs);
      this.cityIsEmpty$.next(res.cityIsEmpty);
    });
  }

  clear(): void {
    this.loading$.next(false);
    this.results$.next(null);
    this.pagination$.next(null);
    this.refs$.next(null);
    this.cityIsEmpty$.next(null);
  }

  generateUrl(host: string[], filters = {}, sort?: Sorts, page?: number): string {
    const queryParams = {...filters, ...{ sort }, ...{ page }};
    const tree = this.router.createUrlTree(host, { queryParams });
    const url =  this.serializer.serialize(tree).substring(1);
    return url;
  }
}
