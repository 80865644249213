<div class="content">
  <span class="title">Восстановление пароля</span>

  <p class="recovery-text">Введите ваш e-mail и мы вышлем на него ссылку для восстановления пароля.</p>

  <form [formGroup]="form" (ngSubmit)="apply()">
    <div class="input-wrapper" [class.hidden]="tooOften" [class.error]="(form.controls.email.invalid && form.controls.email.touched)">
      <span class="label">E-mail:</span>
      <input class="input" formControlName="email">
      <span class="validation-error" *ngIf="form.controls.email.hasError('required') && form.controls.email.touched">Введите e-mail</span>
      <span class="validation-error" *ngIf="form.controls.email.hasError('email') && form.controls.email.touched">Введите корректный e-mail</span>
      <span class="validation-error" *ngIf="emailIsNotFound">Учетной записи с таким e-mail не найдено</span>
    </div>

    <span class="validation-error validation-error--timeout" *ngIf="tooOften">Следующий запрос можно будет отправить через {{timeout}} сек.</span>

    <div class="buttons">
      <button class="button apply-button" [disabled]="form.invalid || tooOften">Восстановить</button>
    </div>
  </form>

</div>

<div class="footer">
  <span class="registration-text">Вспомнили пароль?</span>
  <span class="login" (click)="login()">Войдите</span>
</div>
