import { isPlatformServer, isPlatformBrowser } from '@angular/common';
import { Observable } from 'rxjs';
import { UserService } from '@core/services/user.service';
import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { map } from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private userService: UserService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: object
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {


    if (isPlatformBrowser(this.platformId)) {
      return this.userService.getCurrent().pipe(map((data) => {
        if (data) {
          return true;

        } else {
          this.router.navigate(['/login']);
          return false;
        }
      }));
    }

    return true;

  }
}
