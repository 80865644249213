import { PipesModule } from '@shared/pipes/pipes.module';
import { ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RecoveryFormComponent } from './recovery-form.component';



@NgModule({
  declarations: [RecoveryFormComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    PipesModule
  ],
  exports: [
    RecoveryFormComponent
  ]
})
export class RecoveryFormModule { }
