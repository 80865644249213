import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Loader2Component } from './loader2.component';



@NgModule({
  declarations: [Loader2Component],
  imports: [
    CommonModule
  ],
  exports: [
    Loader2Component
  ]
})
export class Loader2Module { }
