import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScrollToTopButtonComponent } from './scroll-to-top-button.component';
import { NgxPageScrollModule } from 'ngx-page-scroll';


@NgModule({
  declarations: [ScrollToTopButtonComponent],
  imports: [
    CommonModule,
    NgxPageScrollModule
  ],
  exports: [
    ScrollToTopButtonComponent
  ]
})
export class ScrollToTopButtonModule { }
