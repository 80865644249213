import { SearchLoadingDisplayModule } from './../search/search-loading-display/search-loading-display.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { MainComponent } from './main.component';
import { BottomMenuModule } from '@shared/components/bottom-menu/bottom-menu.module';
import { ScrollToTopButtonModule } from '@shared/components/scroll-to-top-button/scroll-to-top-button.module';
import { FooterModule } from '@shared/components/footer/footer.module';
import { HeaderModule } from '@shared/components/header/header.module';


@NgModule({
  declarations: [
    MainComponent
  ],
  imports: [
    CommonModule,
    HeaderModule,
    FooterModule,
    RouterModule,
    BottomMenuModule,
    SearchLoadingDisplayModule,
    ScrollToTopButtonModule
  ],
  exports: [
    MainComponent
  ]
})
export class MainModule { }
