import { BehaviorSubject, timer } from 'rxjs';
import { DOCUMENT, isPlatformServer } from '@angular/common';
import { Injectable, PLATFORM_ID, Inject } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  public showHeader$ = new BehaviorSubject(true);
  public showFooter$ = new BehaviorSubject(true);
  public background$ = new BehaviorSubject(false);
  public footerUnderDisplay$ = new BehaviorSubject(true);

  private previousScrollPosition: { top: number, left: number };
  private pageScrollIsDisabled = false;

  constructor(@Inject(PLATFORM_ID) private platformId) { }


  setHeaderVisibility(status): void {
    timer().subscribe(() => {
      this.showHeader$.next(status);
    });
  }

  setFooterVisibility(status): void {
    timer().subscribe(() => {
      this.showFooter$.next(status);
    });
  }

  setBackground(status): void {
    timer().subscribe(() => {
      this.background$.next(status);
    });
  }

  disablePageScroll(scrolled = 0): void {
    if (this.pageScrollIsDisabled) {
      return;
    }

    this.previousScrollPosition = this.getViewportScrollPosition();

    document.querySelector('html').classList.add('cdk-global-scrollblock');
    document.querySelector('html').style.top = -scrolled + 'px';
    document.querySelector('html').style.left = '0';
    this.pageScrollIsDisabled = true;
  }

  enablePageScroll(): void {
    if (!this.pageScrollIsDisabled) {
      return;
    }

    document.querySelector('html').classList.remove('cdk-global-scrollblock');
    document.querySelector('html').style.top = 'auto';
    window.scroll(0, this.previousScrollPosition.top);
    this.pageScrollIsDisabled = false;
  }

  setFooterUnderDisplay(state) {
    this.footerUnderDisplay$.next(state);
  }

  getViewportScrollPosition() {
    if (isPlatformServer(this.platformId)) {
      return { top: 0, left: 0 };
    }

    const documentElement = document.documentElement;
    const documentRect = documentElement.getBoundingClientRect();

    const top = -documentRect.top || document.body.scrollTop || window.scrollY ||
                 documentElement.scrollTop || 0;

    const left = -documentRect.left || document.body.scrollLeft || window.scrollX ||
                  documentElement.scrollLeft || 0;

    return { top, left };
  }




}
