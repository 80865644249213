import { Loader2Module } from '@shared/ui-kit/loader2/loader2.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchLoadingDisplayComponent } from './search-loading-display.component';



@NgModule({
  declarations: [SearchLoadingDisplayComponent],
  imports: [
    CommonModule,
    Loader2Module
  ],
  exports: [
    SearchLoadingDisplayComponent
  ]
})
export class SearchLoadingDisplayModule { }
