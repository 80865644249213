import { ImageFileInfo } from './../interfaces/file-info-interface';
import { ImageMultiplyValidation, ImageValidationErrors } from './../interfaces/image-validation.interface';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ImageValidatorsService {

  constructor() { }

  validate(info: ImageFileInfo, requirements: ImageMultiplyValidation) {
    const errors: ImageValidationErrors = {};

    if (!info.type) {
      errors.types = true;
      return errors;
    }

    if (!requirements.types.includes(info.type)) {
      errors.types = true;
    }

    if (info.size > requirements.maxSize) {
      errors.maxSize = true;
    }

    if (info.width > requirements.maxWidth) {
      errors.maxWidth = true;
    }

    if (info.width < requirements.minWidth) {
      errors.minWidth = true;
    }

    if (info.height > requirements.maxHeight) {
      errors.maxHeight = true;
    }

    if (info.height < requirements.minHeight) {
      errors.minHeight = true;
    }

    return errors;
  }
}
