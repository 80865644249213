import { Unsubscribe } from '@core/helpers/unsubscribe';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { fromEvent } from 'rxjs';

@Component({
  selector: 'app-scroll-to-top-button',
  templateUrl: './scroll-to-top-button.component.html',
  styleUrls: ['./scroll-to-top-button.component.scss']
})
export class ScrollToTopButtonComponent extends Unsubscribe implements OnInit {

  public duration = 300;
  public show: boolean;

  private breakpoint = 1000;

  constructor() {
    super();
  }

  ngOnInit(): void {
    fromEvent(window, 'scroll').pipe(
      takeUntil(this.destroy$),
      map(() => document.documentElement.scrollTop >= this.breakpoint),
      distinctUntilChanged()
    ).subscribe(state => {
      this.show = state;
    });
  }

}
