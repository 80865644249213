import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PipesModule } from './../../pipes/pipes.module';
import { AvatarModule } from './../avatar/avatar.module';
import { AccountMenuComponent } from './account-menu.component';


@NgModule({
  declarations: [AccountMenuComponent],
  imports: [
    CommonModule,
    RouterModule,
    AvatarModule,
    PipesModule
  ],
  exports: [AccountMenuComponent]
})
export class AccountMenuModule { }
