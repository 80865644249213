import { StringToColorService } from './services/string-to-color.service';
import { RouterEventsService } from './services/router-events.service';
import { ImagesService } from './services/images.service';
import { NoAuthGuard } from './guards/no-auth.guard';
import { AuthGuard } from './guards/auth.guard';
import { LayoutService } from './services/layout.service';
import { DeviceService } from './services/device.service';
import { UserService } from './services/user.service';
import { ApiService } from './services/api.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';



@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    ApiService,
    UserService,
    DeviceService,
    LayoutService,
    AuthGuard,
    NoAuthGuard,
    ImagesService,
    StringToColorService,
    RouterEventsService
  ]
})
export class CoreModule { }
