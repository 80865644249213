import { StringToColorService } from '@core/services/string-to-color.service';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnInit {

  letter: string;
  color: string;
  wrapperStyle;
  letterStyle;

  @Input() name: string;
  @Input() id: string;
  @Input() size = 48;

  constructor(
    private stringToColorService: StringToColorService
  ) { }

  ngOnInit(): void {
    this.letter = this.name[0].toUpperCase();
    this.color = this.stringToColorService.getColor(this.id);

    this.wrapperStyle = {
      'background-color': this.color,
      'width': `${this.size}px`,
      'height': `${this.size}px`,
      'border-radius': `${this.size / 2}px`,
    };

    this.letterStyle = {
      'font-size': `${this.size / 2}px`
    };
  }

}
