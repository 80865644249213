<div class="container-fluid footer-container">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="wrapper">
          <div class="menu">
            <ul>
              <li><a href="" [routerLink]="['/']">Главная</a></li>
              <li><a href="" [routerLink]="['/', 'cats', 'search']">Котики</a></li>
              <li><a href="" [routerLink]="['/', 'cats', 'new']">Создать объявление</a></li>
              <li><a href="" [routerLink]="['/', 'articles']">Статьи о кошках</a></li>
              <li><a href="" [routerLink]="['/', 'feedback']">Обратная связь</a></li>
              <li><a href="" [routerLink]="['/', 'faq']">Вопрос – Ответ</a></li>
              <li class="telegram">
                <a href="https://t.me/koto_poisk" target="_blank">
                  <img src="../../../../assets/tg-icon.png" alt="">
                  <span>t.me/koto_poisk</span>
                </a>
              </li>
            </ul>
          </div>
          <span class="copy">© {{year}} kotopoisk.ru — здесь можно купить кошку или взять котят в добрые руки</span>
        </div>
      </div>
    </div>
  </div>
</div>
