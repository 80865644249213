import { AbstractControl, ValidationErrors } from '@angular/forms';
import { EMAIL_PATTERN } from '../constants/regexp-patterns.const';

export class CustomFormValidators {

  static emailValidator(control: AbstractControl): ValidationErrors | null {
    const email = control.value as string;

    if (email.length === 0) {
      return null;
    }

    if (!email.match(EMAIL_PATTERN)) {
      return { invalidEmail: true };
    }
  }

  static passwordValidator(control: AbstractControl): ValidationErrors | null {
    const password = control.value as string;

    if (password.length === 0) {
      return null;
    }

    if (password.match(/[А-яа-я]/)) {
      return { invalidPassword: true };
    }
  }

  static nameValidator(control: AbstractControl): ValidationErrors | null {
    const name = control.value as string;

    if (name?.length === 0) {
      return null;
    }

    // if (!(/^[а-яА-ЯёЁ ]+$/).test(name)) {
    //   return { invalidName: true };
    // }
  }
}
