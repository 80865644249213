export const MAIL_SERVICES = [

  {
    domain: 'mail.ru',
    name: 'Почта Mail.Ru',
    url: 'https://e.mail.ru/',
  },

  {
    domain: 'bk.ru',
    name: 'Почта Mail.Ru (bk.ru)',
    url: 'https://e.mail.ru/',
  },

  {
    domain: 'list.ru',
    name: 'Почта Mail.Ru (list.ru)',
    url: 'https://e.mail.ru/',
  },

  {
    domain: 'inbox.ru',
    name: 'Почта Mail.Ru (inbox.ru)',
    url: 'https://e.mail.ru/',
  },

  {
    domain: 'yandex.ru',
    name: 'Яндекс.Почта',
    url: 'https://mail.yandex.ru/',
  },

  {
    domain: 'ya.ru',
    name: 'Яндекс.Почта',
    url: 'https://mail.yandex.ru/',
  },

  {
    domain: 'yandex.ua',
    name: 'Яндекс.Почта',
    url: 'https://mail.yandex.ua/',
  },

  {
    domain: 'yandex.by',
    name: 'Яндекс.Почта',
    url: 'https://mail.yandex.by/',
  },

  {
    domain: 'yandex.kz',
    name: 'Яндекс.Почта',
    url: 'https://mail.yandex.kz/',
  },

  {
    domain: 'yandex.com',
    name: 'Yandex.Mail',
    url: 'https://mail.yandex.com/',
  },

  {
    domain: 'gmail.com',
    name: 'Gmail',
    url: 'https://mail.google.com/',
  },

  {
    domain: 'googlemail.com',
    name: 'Gmail',
    url: 'https://mail.google.com/',
  },

  {
    domain: 'outlook.com',
    name: 'Outlook.com',
    url: 'https://mail.live.com/',
  },

  {
    domain: 'hotmail.com',
    name: 'Outlook.com (Hotmail)',
    url: 'https://mail.live.com/',
  },

  {
    domain: 'live.ru',
    name: 'Outlook.com (live.ru)',
    url: 'https://mail.live.com/',
  },

  {
    domain: 'live.com',
    name: 'Outlook.com (live.com)',
    url: 'https://mail.live.com/',
  },

  {
    domain: 'me.com',
    name: 'iCloud Mail',
    url: 'https://www.icloud.com/',
  },

  {
    domain: 'icloud.com',
    name: 'iCloud Mail',
    url: 'https://www.icloud.com/',
  },

  {
    domain: 'rambler.ru',
    name: 'Рамблер-Почта',
    url: 'https://mail.rambler.ru/',
  },

  {
    domain: 'yahoo.com',
    name: 'Yahoo! Mail',
    url: 'https://mail.yahoo.com/',
  },

  {
    domain: 'ukr.net',
    name: 'Почта ukr.net',
    url: 'https://mail.ukr.net/',
  },

  {
    domain: 'i.ua',
    name: 'Почта I.UA',
    url: 'http://mail.i.ua/',
  },

  {
    domain: 'bigmir.net',
    name: 'Почта Bigmir.net',
    url: 'http://mail.bigmir.net/',
  },

  {
    domain: 'tut.by',
    name: 'Почта tut.by',
    url: 'https://mail.tut.by/',
  },

  {
    domain: 'inbox.lv',
    name: 'Inbox.lv',
    url: 'https://www.inbox.lv/',
  },

  {
    domain: 'mail.kz',
    name: 'Почта mail.kz',
    url: 'http://mail.kz/',
  }
];
