import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { PipesModule } from '@shared/pipes/pipes.module';
import { AvatarModule } from '@shared/components/avatar/avatar.module';
import { ClickOutsideModule } from '@shared/directives/click-outside/click-outside.module';
import { HeaderComponent } from './header.component';
import { AuthDialogModule } from '@shared/dialogs/auth-dialog/auth-dialog.module';



@NgModule({
  declarations: [HeaderComponent],
  imports: [
    CommonModule,
    AuthDialogModule,
    ClickOutsideModule,
    RouterModule,
    AvatarModule,
    PipesModule
  ],
  exports: [
    HeaderComponent
  ]
})
export class HeaderModule { }
