import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';

import { first } from 'rxjs/operators';

import { RouterEventsService } from '@core/services/router-events.service';
import { RouteHistoryService } from '@core/services/route-history.service';
import { Unsubscribe } from '@core/helpers/unsubscribe';
import { UserService } from '@core/services/user.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends Unsubscribe implements OnInit {

  constructor(
    private userService: UserService,
    private routeHistoryService: RouteHistoryService,
    private routerEventsService: RouterEventsService,
    @Inject(PLATFORM_ID) private platformId: object
  ) {
    super();
  }

  ngOnInit(): void {
    navigator?.serviceWorker?.getRegistrations().then(registrations => {
      for (const registration of registrations) {
       registration.unregister();
     }});

    this.routeHistoryService.start();
    this.routerEventsService.setScrollTopOnChangeRouter();

    if (isPlatformBrowser(this.platformId)) {
      this.userService.getCurrent().pipe(first()).subscribe();
    }
  }

}
